import React, { useState } from "react";
import { TextField, Button, Container, Typography, CircularProgress, Alert } from "@mui/material";

function App() {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value.toUpperCase());
  };

  const makeRequest = () => {
    const url = `https://callsigns.kk7lhm.net/callsigns/${encodeURIComponent(inputValue)}.json`;
    setLoading(true);
    setErrorMessage("");
    setResponseData(null);

    fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
          }
          return response.json();
        })
        .then((data) => {
          setResponseData(data);
          setLoading(false);
        })
        .catch((error) => {
          setErrorMessage(error.message);
          setLoading(false);
        });
  };

  return (
      <Container maxWidth="sm" style={{ marginTop: "50px" }}>
        <Typography variant="h4" gutterBottom>
          Callsign Lookup
        </Typography>

        <TextField
            label="Enter a callsign"
            variant="outlined"
            fullWidth
            margin="normal"
            value={inputValue}
            onChange={handleInputChange}
        />

        <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={makeRequest}
            disabled={loading}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Submit"}
        </Button>

        <div style={{ marginTop: "20px" }}>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          {responseData && (
              <pre style={{ background: "#272822", color: "#f8f8f2", padding: "15px", borderRadius: "5px" }}>
            {JSON.stringify(responseData, null, 2)}
          </pre>
          )}
        </div>
      </Container>
  );
}

export default App;